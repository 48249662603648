<template>
  <b-container fluid>
    <b-row align-h="center">
      <b-col cols="12" md="4">
        <b-card title="Login" class="mb-2" bg-variant="white">
            <b-form @submit="login">
              <b-form-group id="input-group-1" label="Email address:" label-for="input-1">
                <b-form-input
                  id="input-1"
                  v-model="email"
                  type="email"
                  inputmode="email"
                  required
                  placeholder="Enter email"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Password:" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="password"
                  type="password"
                  inputmode="password"
                  required
                  placeholder="Password"
                ></b-form-input>
              </b-form-group>

              <b-button type="submit" variant="primary">Submit</b-button>
              <p class="mt-4">
                Don't have an account?
                <router-link to="/signup">Sign up here.</router-link>
              </p>
              <b-alert variant="warning" v-if="err.message" class="error-message">{{ err.message }}</b-alert>
            </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

  import { auth } from '../firebaseConfig'
  import { store } from '../store'

  export default {
    name: 'login',
    data() {
      return {
        email: '',
        password: '',
        err: {}
      }
    },
    created () {
    },
    methods: {
      login(e) {
        e.preventDefault()
        auth.signInWithEmailAndPassword(this.email, this.password).then(cred => {
          store.commit('setCurrentUser', cred.user)
          store.dispatch('getUserProfile').then(() => {
            this.$router.push('/teamlist')
          })
        },
        err => {
          console.error(err)
        })
      }
    }
  }
</script>

<style scoped>  /* "scoped" attribute limit the CSS to this component only */
</style>